<template>
  <div>
    <el-drawer
      custom-class="task-details"
      size="1240px"
      :visible.sync="visible"
      :wrapperClosable="false"
      destroy-on-close
      :title="title"
      @closed="closed"
      :append-to-body="true"
    >
      <div class="loading-wrapper" v-loading="loading">
        <div class="intro mb6">
          <hb-fragment
            v-layout-flex="{ minWidth: 280, nodeNum: 25, maxWidth: 400 }"
            :detailData="detailData"
          ></hb-fragment>
          <div class="hb-fragment__item">
            <p class="hb-fragment__title">任务描述</p>
            <div class="hb-fragment__value">
              <div v-html="taskInfo.description || '-'" class="hb-fragment__value--disabled"></div>
            </div>
          </div>
          <div>
            <div class="mb5" style="color: #999">我需要的帮助</div>
            <div
              class="content mb20"
              style="white-space: pre-line"
              v-for="(item, index) in taskInfo.needHelp"
              :key="index"
            >
              <div class="mb5">{{ item.content }}</div>
              <span class="mr10" style="color: #999">更新人 {{ item.modifyName }}</span>
              <span class="mr10" style="color: #999">更新时间 {{ item.modifyTime }}</span>
            </div>
          </div>
          <div>
            <div class="mb5" style="color: #999">输出结果</div>
            <div
              class="content mb20"
              style="white-space: pre-line"
              v-for="(item, index) in taskInfo.keyResult"
              :key="index"
            >
              <div class="mb5">{{ item.content }}</div>
              <span class="mr10" style="color: #999">更新人 {{ item.modifyName }}</span>
              <span class="mr10" style="color: #999">更新时间 {{ item.modifyTime }}</span>
            </div>
          </div>

          <div class="hb-fragment__item">
            <p class="hb-fragment__title">附件</p>
            <HbUpload class="file-unit-wrapper" :editAble="false" :list="taskInfo.files" />
          </div>
          <template v-if="taskInfo.child && taskInfo.child.length > 0">
            <div class="mb9" style="font-size: 13px; font-weight: bold; color: #111">子任务</div>
            <hb-table
              style="min-height: 70px"
              :empty="true"
              :key="tableKey"
              class="pb20"
              :columns="columns"
              :tableData="taskInfo.child"
            ></hb-table>
          </template>
        </div>
        <el-tabs v-model="activeTabName" @tab-click="currentTabChange">
          <el-tab-pane label="评论" name="comment">
            <PublicComments
              :eventId="taskId"
              module="任务评论"
              :title="taskInfo.title"
              :showTitle="false"
              defaultShowSize="3"
            />
          </el-tab-pane>
          <el-tab-pane label="验收" name="score">
            <el-radio-group v-model="taskInfo.score" size="mini" class="mb20">
              <el-radio-button
                v-for="item in scoreList"
                :key="item"
                :label="item"
                :disabled="taskInfo.score != item"
              ></el-radio-button>
            </el-radio-group>
            <hb-fragment
              v-layout-flex="{ minWidth: 280, nodeNum: 1, maxWidth: 400 }"
              :detailData="acceptanceData"
            ></hb-fragment>
            <div class="mb10 mt10">奖罚</div>
            <div class="mb10" v-if="taskInfo.rewardPunishmentList.length > 0">
              奖罚总额：{{ rewardTotal }}
            </div>
            <el-row
              class="reward-list"
              :gutter="20"
              v-for="(item, index) in taskInfo.rewardPunishmentList"
              :key="index"
            >
              <el-col :span="3">申请人：{{ getName(item.proposer) }}</el-col>
              <el-col :span="3">奖罚人：{{ getName(item.rewardPunishmentOfficer) }}</el-col>
              <el-col :span="3">奖罚金额：{{ item.amount }}</el-col>
              <el-col :span="3">比例：{{ item.ratio + "%" }}</el-col>
              <el-col :span="12">奖罚原因：{{ item.reason }}</el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="历史记录" name="log">
            <el-collapse ref="collapse" v-model="activeLogs">
              <el-collapse-item
                v-for="(item, index) in historyLog"
                :name="index"
                :key="index"
                :disabled="item.type !== '406'"
              >
                <template slot="title">
                  <div class="log-title">
                    <div class="info-group">
                      <div class="expand-arrow" v-if="item.type === '406'">
                        <i
                          :class="[
                            activeLogs.includes(index) && 'active',
                            'icon-arrow',
                            'icon-main-zhankai',
                          ]"
                        ></i>
                      </div>
                      <img class="avatar log" :src="item.logo" alt="avatar" />
                      <span class="username">{{ item.createUserName }}</span>
                      <span>{{ item.logData }}</span>
                    </div>
                    <span class="date-time">{{ item.createTime }}</span>
                  </div>
                </template>
                <template v-if="item.type === '406'">
                  <p class="log-data">
                    <span class="title">修改前：</span>
                    <span class="content" v-html="item.oldData || '--'"></span>
                  </p>
                  <p class="log-data">
                    <span class="title">修改后：</span>
                    <span class="content" v-html="item.nowData || '--'"></span>
                  </p>
                </template>
              </el-collapse-item>
            </el-collapse>
          </el-tab-pane>
        </el-tabs>
      </div>

      <div class="hb-drawer-footer">
        <el-button
          :disabled="taskAuthDisabled('开始')"
          @click="dispatchDialog({ dialogType: 'startTask', params: taskInfo })"
        >
          开始
        </el-button>
        <el-button
          :disabled="taskAuthDisabled('完成')"
          @click="dispatchDialog({ dialogType: 'finishTask', params: taskInfo })"
        >
          完成
        </el-button>
        <el-button
          :disabled="taskAuthDisabled('编辑')"
          @click="
            dispatchDialog({
              dialogType: 'editTask',
              params: { type: 'edit', detail: taskInfo.id },
            })
          "
        >
          编辑
        </el-button>
        <el-button
          v-if="!taskInfo.pid"
          :disabled="taskAuthDisabled('子任务')"
          @click="dispatchDialog({ dialogType: 'subtask', params: taskInfo })"
        >
          子任务
        </el-button>
        <el-button
          :disabled="taskAuthDisabled('发包人关闭')"
          @click="
            dispatchDialog({
              dialogType: 'closeTask',
              params: { ...taskInfo, closeStatus: 'closedCreateUser' },
            })
          "
        >
          发包人关闭
        </el-button>
        <el-button
          :disabled="taskAuthDisabled('失败关闭')"
          @click="
            dispatchDialog({
              dialogType: 'closeTask',
              params: { ...taskInfo, closeStatus: 'closedFail' },
            })
          "
        >
          失败关闭
        </el-button>
        <el-button
          :disabled="taskAuthDisabled('设置进度')"
          @click="dispatchDialog({ dialogType: 'progressTask', params: taskInfo })"
        >
          设置进度
        </el-button>
        <el-button
          :disabled="taskAuthDisabled('复制')"
          @click="
            dispatchDialog({
              dialogType: 'editTask',
              params: { type: 'copy', detail: taskInfo.id },
            })
          "
        >
          复制
        </el-button>
        <el-button :disabled="taskAuthDisabled('删除')" @click="removeTask(taskInfo)">
          删除
        </el-button>
      </div>
    </el-drawer>
    <hb-organize-dialog :memberOnly="true" ref="organize" @confirm="confirmOrganize" />
  </div>
</template>

<script>
import PublicComments from "@/views/publicComponents/publicComments.vue";
export default {
  name: "taskDetails",
  components: {
    PublicComments,
  },
  data() {
    return {
      scoreList: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      taskId: null,
      visible: false,
      taskInfo: { rewardPunishmentList: [] },
      activeTabName: "comment",
      columns: [
        {
          prop: "title",
          label: "任务目标",
          minWidth: 180,
          align: "left",
          currentSelect: true,
        },
        {
          prop: "priority",
          label: "优先级",
          width: 45,
          currentSelect: true,
        },
        {
          prop: "taskCategoryName",
          label: "任务类别",
          width: 60,
          currentSelect: true,
        },
        {
          prop: "createUserName",
          label: "发包人",
          width: 45,
          currentSelect: true,
        },
        {
          prop: "createProductLineName",
          label: "发包产品线",
          width: 80,
          align: "left",
          currentSelect: true,
        },
        {
          prop: "contractorName",
          label: "承包人",
          width: 45,
          currentSelect: true,
        },
        {
          width: 50,
          prop: "assistPmName",
          label: "辅助PM",
          currentSelect: true,
        },
        {
          prop: "productLineName",
          label: "承包产品线",
          width: 80,
          align: "left",
          currentSelect: true,
        },
        {
          width: 70,
          prop: "areaName",
          label: "区域中心",
          currentSelect: true,
          align: "left",
        },
        {
          width: 60,
          prop: "difficulty",
          label: "任务难度",
          currentSelect: true,
        },
        {
          prop: "plannedDeliveryTime",
          label: "期望交付时间",
          width: 80,
          currentSelect: true,
        },
        {
          prop: "expectedStartTime",
          label: "计划开始时间",
          width: 80,
          currentSelect: true,
        },
        {
          prop: "expectedEndTime",
          label: "承诺交付时间",
          width: 80,
          currentSelect: true,
        },
        {
          prop: "description",
          label: "任务描述",
          width: 150,
          align: "left",
          currentSelect: true,
        },
        {
          prop: "needHelp",
          label: "我需要的帮助",
          width: 150,
          align: "left",
          currentSelect: true,
          formatter: (row) => row.needHelp[0]?.content,
        },
        {
          prop: "keyResult",
          label: "输出结果",
          width: 150,
          align: "left",
          currentSelect: true,
          formatter: (row) => row.keyResult[0]?.content,
        },
      ],
      historyLog: [],
      activeLogs: [],
      loading: false,
      tableKey: Date.now(),
      componentName: "",
    };
  },
  computed: {
    detailData() {
      const data = this.taskInfo;
      return [
        {
          name: "按灯",
          value: data.light,
          disabled: true,
        },
        {
          name: "任务目标",
          value: data.title,
          disabled: true,
        },
        {
          name: "优先级",
          value: data.priority,
          disabled: true,
        },
        {
          name: "任务类型",
          value: data.taskTypeName,
          disabled: true,
        },
        {
          name: "关联单据",
          value: data.documentName,
          route: () => {
            let taskType = data.taskType,
              routeMap;
            if (!taskType) return;
            if (["1", "4", "6", "7", "8"].includes(taskType)) {
              // 跳转列表页，打开详情弹窗
              routeMap = {
                1: "/micro-platform/andonManage",
                4: "/micro-platform/pmoProject",
                6: "/micro-platform/pmoDemand",
                7: "/micro-platform/pmoTechnology",
                8: "/micro-produce/produceOrder",
              };
              if (this.$route.fullPath == routeMap[taskType]) return; // 如果是在单据的页面里打开任务详情弹窗，点击关联单据就没必要跳转了
              this.$router.push({ path: routeMap[taskType] });
              window.sessionStorage.setItem(
                routeMap[taskType] + (taskType == 7 ? "/businessId/detail" : "/businessId"),
                data.document,
              );
            } else {
              // 跳转详情页
              routeMap = {
                5: `/micro-market/transaction/commercialOpportunity/detail/${data.customerId}/TabDetailBusiness/${data.document}/commercialOpportunity`,
                9: `/micro-market/transaction/orderSheet/detail/${data.customerId}/TabOrderDetail/${data.document}/orderSheet`,
                10: `/micro-produce/produceOrder/detail/${data.document}`,
                11: `/micro-produce/internalCoordinationOrder/detail/${data.document}`,
                12: `/micro-purchase/purchaseOrder/detail/${data.document}`,
              };
              if (this.$route.path == routeMap[taskType]) return; // 如果是在单据的页面里打开任务详情弹窗，点击关联单据就没必要跳转了
              this.$router.push({ path: routeMap[taskType] });
            }
            this.closed();
          },
          disabled: true,
        },
        {
          name: "任务类别",
          value: data.taskCategory,
          disabled: true,
        },
        {
          name: "任务难度",
          value: data.difficulty,
          disabled: true,
        },
        {
          name: "承包人",
          value: data.contractorName,
          disabled: true,
        },
        {
          name: "辅助PM",
          value: data.assistPmName,
          disabled: true,
        },
        {
          name: "部门",
          value: data.responsibleDeptName,
          disabled: true,
        },
        {
          name: "区域中心",
          value: data.areaName,
          disabled: true,
        },
        {
          name: "承包产品线",
          value: data.productLineName,
          disabled: true,
        },
        {
          name: "发包时间",
          value: data.createTime,
          disabled: true,
        },
        {
          name: "已发包时长",
          value: data.duration,
          disabled: true,
        },
        {
          name: "实际开始",
          value: data.actualStartTime?.split(" ")[0],
          disabled: true,
        },
        {
          name: "期望交付",
          value: data.plannedDeliveryTime?.split(" ")[0],
          disabled: true,
        },
        {
          name: "承诺交付",
          value: data.expectedEndTime?.split(" ")[0],
          disabled: true,
        },
        {
          name: "实际交付",
          value: data.actualFinishTime,
          disabled: true,
        },
        {
          name: "延误d",
          value: data.delayDays,
          disabled: true,
        },
        {
          name: "计划开始时间",
          value: data.expectedStartTime?.split(" ")[0],
          disabled: true,
        },
        {
          name: "验收方式",
          value: data.checkingWay,
          disabled: true,
        },
        {
          name: "状态",
          value: data.statusName,
          disabled: true,
        },
        {
          name: "发包人",
          value: data.createUserName,
          disabled: true,
        },
        {
          name: "发包产品线",
          value: data.createProductLineName,
          disabled: true,
        },
        {
          name: "抄送人",
          value: data.copys?.map((item) => item.name)?.join("、"),
          disabled: true,
        },
        {
          name: "按灯说明",
          value: data.lightExplain,
          disabled: true,
        },
        {
          name: "验收标准",
          value: data.acceptanceStandard,
          disabled: true,
        },
      ];
    },
    acceptanceData() {
      const data = this.taskInfo;
      return [
        {
          name: "验收人",
          value: data.checkerName,
          disabled: true,
        },
        {
          name: "验收说明",
          value: data.checkRemark,
          disabled: true,
        },
      ];
    },
    userList() {
      return this.$store.state.usersList;
    },
    getName() {
      return (userId) => this.userList.find((i) => i.userId === userId).name;
    },
    avatar() {
      return this.$store.state.userInfo.logo;
    },
    userId() {
      return this.$store.state.userInfo.id;
    },
    taskAuthDisabled() {
      return function (todo) {
        return this.$store.getters.taskAuthGetters(this.taskInfo, todo);
      };
    },
    title() {
      return this.taskInfo.pName
        ? `${this.taskInfo.pName} / ${this.taskInfo.title}`
        : this.taskInfo.title;
    },
    rewardTotal() {
      return this.taskInfo.rewardPunishmentList?.reduce((a, b) => {
        return a + Number(b.amount);
      }, 0);
    },
  },
  watch: {
    "taskInfo.child.length": {
      handler: function () {
        this.tableKey = Date.now();
      },
    },
  },
  methods: {
    open({ id, componentName }) {
      this.componentName = componentName; // 用于解决在同一个页面存在多个任务清单组件的事件冲突问题
      this.visible = true;
      this.taskId = id;
      this.getTaskInfo();
      this.getHistoryLog(id);
      window.addEventListener("taskRefreshable", this.getTaskInfo); // 打开详情的时候再去监听是否可以刷新任务数据
    },
    closed() {
      this.visible = false;
      this.activeTabName = "comment";
      window.removeEventListener("taskRefreshable", this.getTaskInfo);
    },
    currentTabChange() {},
    openOrganize(userList = []) {
      this.$refs.organize.open();
      const rewriteAuthList = userList.map((item) => ({
        ...item,
        isDept: "0",
      }));
      this.$refs.organize.init(this.$store.state.fullOrganizationData, rewriteAuthList);
    },
    confirmOrganize({ localChecked }) {
      this.$refs.richEditor.createMentionTag(localChecked);
      this.$refs.organize.close();
    },
    // taskRefreshFlag  是否是刷新操作
    getTaskInfo(taskRefreshFlag) {
      this.loading = true;
      this.$http
        .get(`/task/detail/${this.taskId}`, {
          params: { detail: true },
        })
        .then((res) => {
          res.score && (res.score = Number(res.score));
          this.taskInfo = res;
          // 如果是在任务清单点击详情进入的，则 任务数据更新后要强制替换任务清单对应的数据
          taskRefreshFlag &&
            this.$emit("refreshTaskList", {
              dialogType: "edit",
              formData: res,
              componentName: this.componentName,
            });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 获取历史记录
    getHistoryLog(taskId) {
      this.$http.get(`/taskLog/list/${taskId}`).then((res) => {
        this.historyLog = res;
      });
    },
    // 删除附件
    removeAnnex(item, index) {
      this.$http
        .get("/task/deleteFile", { params: { taskId: this.taskId, fileId: item.id } })
        .then((res) => {
          this.taskInfo.files.splice(index, 1);
          this.$message.success("操作成功");
        });
    },
    downloadAnnex(item) {
      let downloadElement = document.createElement("a");
      downloadElement.href = item.downloadUrl;
      downloadElement.download = item.name;
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
      downloadElement = null;
    },
    // 打开对应任务操作弹窗
    dispatchDialog(params) {
      this.$emit("dispatchDialog", params);
    },
    // 删除任务
    removeTask(taskInfo) {
      this.$confirm(`请确认是否删除任务${taskInfo.title}`, "提示")
        .then(() => {
          this.$http.post("/task/remove", { ids: [taskInfo.id] }).then((res) => {
            this.$message.success("操作成功");
            this.$emit("refresh");
            this.closed();
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.reward-list {
  margin-bottom: 10px;
}
.task-details {
  .intro {
    border-bottom: 1px solid #f5f5f5;
  }
  .avatar {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
    &.log {
      width: 26px;
      height: 26px;
    }
  }
  .comment-group {
    display: flex;
    align-items: flex-start;
    .comment-editor {
      width: 100%;
    }
  }
  .comment-list {
    margin-top: 10px;
    .comment-item {
      padding: 0 20px;
      background-color: #fafafa;
      border-radius: 4px;
      border: 1px solid #e5e5e5;
      margin-bottom: 10px;
      .comment-info {
        border-bottom: 1px dashed #e6e6e6;
      }
      .user-group {
        padding: 10px 0;
      }
      .create-time {
        color: #444;
      }
      .remove-btn {
        color: #fa5a55;
        font-size: 13px;
        .icon-remove {
          font-size: 13px !important;
          transform: translateY(1px);
        }
      }
      .username {
        font-size: 14px;
        color: #111;
      }
      .content {
        padding: 10px 0;
      }
      .file-list {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
      }
    }
  }
  .log-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    padding: 0 10px;
    background-color: #f6f6f6;
    border-radius: 4px;
    .info-group {
      display: flex;
      align-items: center;
      .expand-arrow {
        width: 20px;
        height: 20px;
        background: #f7f8fb;
        border: 1px solid #e2e2e3;
        border-radius: 2px;
        text-align: center;
        line-height: 20px;
        margin-right: 10px;
        .icon-arrow {
          display: inline-block;
          font-size: 10px;
          color: #666;
          transition: transform 0.3s;
          &.active {
            transform: rotate(-180deg);
            transition: transform 0.3s;
          }
        }
      }
    }
    .username {
      margin-right: 14px;
    }
    .date-time {
      font-size: 12px;
      color: #444;
    }
  }
  .log-data {
    font-size: 13px;
    .title {
      color: #444;
    }
    .content {
      color: #111;
    }
  }
  .hb-fragment__item {
    padding-bottom: 20px;
    margin-right: 0px;
  }
  .hb-fragment__value {
    width: 100%;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  .light-circle {
    &::before {
      vertical-align: middle;
    }
  }
}
::v-deep.el-collapse {
  border: none;
  .el-collapse-item {
    margin-bottom: 10px;
    .el-collapse-item__header {
      color: #111;
      height: 36px;
      line-height: 36px;
      border-bottom: none;
    }
    .el-collapse-item__content {
      background-color: #f6f6f6;
      padding: 10px 12px;
    }
    .el-collapse-item__arrow {
      display: none;
    }
  }
}
::v-deep .el-drawer__body {
  padding: 20px !important;
}
::v-deep .el-tabs.el-tabs--top .el-tabs__content {
  padding: 20px 0px 60px 0px !important;
}
</style>