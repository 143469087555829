import Store from "@/store";
import * as BASES from "@/config/qiankun/base";
import * as Servers from "@/config/microServers";
import { mainMethods } from "@/assets/js/globalUtils";
import { PopupManager } from "element-ui/lib/utils/popup";
import PublicTaskList from "@/views/publicComponents/publicTaskList";
const publicComponents = [PublicTaskList];
let microApps = Object.keys(BASES);
let microAppsConfig = microApps.map((app) => {
  return {
    name: app,
    container: "#microAppContainer",
    activeRule: BASES[app],
    entry: Servers[app][process.env.VUE_APP_ENV].ip,
    props: {
      Store,
      mainMethods,
      api: { market: process.env.VUE_APP_MARKET },
      publicComponents,
      PopupManager, // 用于微应用打开主应用的弹窗或抽屉时会出现的zindex层级错乱问题
    },
  };
});
export default microAppsConfig;
