<template>
  <hb-dialog
    :modal-append-to-body="true"
    :append-to-body="true"
    @closed="closed"
    title="关联任务"
    ref="dialog"
    width="1600px"
    :zIndex="zindex"
    @submit="submit"
  >
    <template slot="normal">
      <hb-advquery
        :showRefresh="false"
        :qryFormProps="qryFormProps"
        :qryFormData="senior"
        :showCusQuery="false"
        @advQuery="getTableData(true)"
        @reset="resetParams"
        :resetClear="false"
      >
        <hb-toolgroup
          slot="tools"
          kwkey="keyWord"
          :params="senior"
          placeholder="任务编号、任务目标"
          @search="getTableData(true)"
        ></hb-toolgroup>
      </hb-advquery>
      <hb-fulltable
        ref="table"
        :columns="columns"
        :tableData="list"
        :pageData="pageData"
        :disabledFilter="true"
        :select="true"
        height="60vh"
        row-key="id"
        :tree-props="{ children: 'child' }"
        @pageSizeChange="handlePageSizeChange"
        @pageNumChange="handlePageNumChange"
        @selectData="handleSelect"
        :selectable="selectable"
      >
        <el-table-column
          slot="light"
          slot-scope="{ column }"
          :label="column.label"
          :prop="column.prop"
          :key="column.prop"
          :width="column.width"
          :align="column.align"
        >
          <template v-slot="{ row }">
            <span
              class="text-center"
              style="display: inline-block; width: 100%"
              @click="changeLight(row)"
            >
              <i
                :class="{
                  'green-light': row.light == '绿灯',
                  'red-light': row.light == '红灯',
                  'yellow-light': row.light == '黄灯',
                  'white-light': !row.light,
                }"
                class="icon-main-andeng1"
              ></i>
            </span>
          </template>
        </el-table-column>
        <el-table-column
          slot="title"
          slot-scope="{ column }"
          :label="column.label"
          :prop="column.prop"
          :key="column.prop"
          :width="column.width"
          :show-overflow-tooltip="true"
          :align="column.align"
        >
          <template v-slot="{ row }">
            <div class="flex-middle flex-between">
              <span v-if="row.pid" class="son-task__char">子</span>
              <span class="jump pointer table-text-overflow" @click="openDetails(row)">
                {{ row.title }}
              </span>
              <div
                class="expand-icon pointer"
                @click="toggleRowExpansion(row)"
                v-if="row.haveChildren"
              >
                <i :class="['arrow', 'icon-main-zhankai', row.expand ? 'expand' : '']"></i>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          slot="status"
          slot-scope="{ column }"
          :label="column.label"
          :prop="column.prop"
          :key="column.prop"
          :width="column.width"
          :fixed="column.fixed"
          :show-overflow-tooltip="true"
          :align="column.align"
        >
          <template v-slot="{ row }">
            <span :class="column.status(row.status)">
              {{ row.statusName }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          slot="score"
          slot-scope="{ column }"
          :label="column.label"
          :prop="column.prop"
          :key="column.prop"
          :width="column.width"
          :fixed="column.fixed"
          :show-overflow-tooltip="true"
          :align="column.align"
        >
          <template v-slot="{ row }">
            <span v-if="column.styleMap[row.score]" :class="column.styleMap[row.score].className">
              {{ column.styleMap[row.score].label }}
            </span>
          </template>
        </el-table-column>
      </hb-fulltable>
    </template>
  </hb-dialog>
</template>
<script>
import { PopupManager } from "element-ui/lib/utils/popup";
import moment from "moment";
export default {
  data() {
    return {
      list: [],
      columns: [
        {
          prop: "code",
          label: "编码",
          width: 45, //每个列表至少有一个自适应列，使用minwidth
          currentSelect: true,
        },
        {
          width: 35,
          prop: "light",
          label: "按灯",
          slot: true,
          currentSelect: true,
        },
        {
          width: 60,
          prop: "lightExplain",
          label: "按灯说明",
          currentSelect: true,
        },
        {
          width: 70,
          prop: "statusName",
          label: "状态",
          currentSelect: true,
        },
        {
          width: 60,
          prop: "closingReason",
          label: "关闭原因",
          formatter: (row) => {
            let data = ["", "已验收", "重复", "已取消", "已拒绝"];
            return data[row.closingReason];
          },
          currentSelect: true,
        },
        {
          width: 190,
          prop: "title",
          label: "任务目标",
          slot: true,
          align: "left",
          currentSelect: true,
        },
        {
          width: 190,
          prop: "documentName",
          label: "关联单据",
          align: "left",
          currentSelect: true,
        },
        {
          prop: "priority",
          label: "优先级",
          width: 70,
          sortable: "custom",
          currentSelect: true,
          align: "right",
        },
        {
          width: 35,
          prop: "difficulty",
          label: "难度",
          align: "right",
          currentSelect: true,
        },
        {
          width: 45,
          prop: "createUserName",
          label: "发包人",
          currentSelect: true,
        },
        {
          width: 45,
          prop: "contractorName",
          label: "承包人",
          currentSelect: true,
        },
        {
          prop: "responsibleDeptName",
          label: "部门",
          width: 90,
          align: "left",
          currentSelect: true,
        },
        {
          width: 140,
          prop: "productLineName",
          label: "产品线",
          currentSelect: true,
          align: "left",
        },
        {
          width: 80,
          prop: "createTime",
          label: "发起时间",
          sortable: "custom",
          currentSelect: true,
          formatter: ({ createTime }) => moment(createTime).format("yyyy-MM-DD"),
        },
        {
          width: 80,
          prop: "actualStartTime",
          label: "实际开始",
          sortable: "custom",
          currentSelect: true,
          formatter: ({ actualStartTime }) =>
            actualStartTime ? moment(actualStartTime).format("yyyy-MM-DD") : "",
        },
        {
          width: 80,
          prop: "actualFinishTime",
          label: "实际交付",
          sortable: "custom",
          currentSelect: true,
          formatter: ({ actualFinishTime }) =>
            actualFinishTime ? moment(actualFinishTime).format("yyyy-MM-DD") : "",
        },
        {
          width: 70,
          prop: "plannedDeliveryTime",
          label: "期望交付",
          currentSelect: true,
          formatter: ({ plannedDeliveryTime }) =>
            plannedDeliveryTime ? moment(plannedDeliveryTime).format("yyyy-MM-DD") : "",
        },
        {
          width: 45,
          prop: "delayDays",
          label: "延误d",
          align: "right",
          currentSelect: true,
        },
        {
          width: 200,
          prop: "needHelp",
          label: "我需要的帮助",
          align: "left",
          currentSelect: true,
          formatter: (row) => row.needHelp[0]?.content,
        },
        {
          width: 60,
          prop: "rewardPunishmentAmount",
          label: "奖罚金额",
          align: "right",
          formatter: (row) => this.$thousandth(row.rewardPunishmentAmount),
          currentSelect: true,
        },
      ],
      senior: {
        keyWord: "",
        priority: [],
        expectedStartTime: [],
        expectedEndTime: [],
        actualFinishTime: [],
      },
      qryFormProps: [
        {
          type: "select",
          label: "优先级",
          prop: "priority",
          placeholder: "请选择优先级",
          multiple: true,
          optList: [
            {
              label: "高",
              value: "高",
            },
            {
              label: "中",
              value: "中",
            },
            {
              label: "低",
              value: "低",
            },
          ],
        },
        {
          type: "daterange",
          label: "计划开始时间",
          prop: "expectedStartTime",
          placeholder: "请选择日期",
        },
        {
          type: "daterange",
          label: "承诺交付时间",
          prop: "expectedEndTime",
          placeholder: "请选择日期",
        },
        {
          type: "daterange",
          label: "实际交付时间",
          prop: "actualFinishTime",
          placeholder: "请选择日期",
        },
      ],
      pageData: {
        pageNum: 1,
        pageSize: 50,
        total: 0,
      },
      selectedData: [],
      choosedList: [],
    };
  },
  computed: {
    zindex() {
      return this.$PopupManager.nextZIndex();
    },
  },
  methods: {
    changeLight(row) {
      window.dispatchEvent(
        new CustomEvent("changeLight", {
          bubbles: false,
          detail: {
            row,
            formData: {
              tableName: "task",
              id: row.id,
              modifyNameKey: "modify_user_name",
              lightExplainKey: "lightExplain",
            },
          },
        }),
      );
    },
    openDetails({ id }) {
      window.dispatchEvent(
        new CustomEvent("dispatchTask", {
          bubbles: false,
          detail: {
            dialogType: "detailsTask",
            params: { id },
          },
        }),
      );
    },
    resetParams() {
      this.senior = {
        keyWord: "",
        priority: [],
        expectedStartTime: [],
        expectedEndTime: [],
        actualFinishTime: [],
      };
    },
    open(choosedList) {
      this.choosedList = choosedList.map((i) => i.id);
      this.$refs.dialog.open();
      this.$nextTick(() => {
        this.getTableData();
      });
      window.addEventListener("taskRefreshable", this.getTableData);
    },
    //设置禁用
    selectable(row) {
      return !this.choosedList.some((id) => row.id === id);
    },
    getTableData(initPageNum = false) {
      this.$refs.table.openLoading();
      initPageNum && (this.pageData.pageNum = 1);
      this.$http
        .post(
          "/task/page",
          Object.assign(this.senior, {
            pageNum: this.pageData.pageNum,
            pageSize: this.pageData.pageSize,
            dimension: 1,
            ifAssociationDocument: false,
          }),
          {
            baseURL: "/platform",
          },
        )
        .then((res) => {
          this.selectedData = [];
          this.list = res.page.records;
          this.pageData.total = res.page.total;
        })
        .finally(() => {
          this.$refs.table.closeLoading();
        });
    },

    submit() {
      let selectingData = this.selectedData.filter(
        (item) => !this.choosedList.some((i) => item.id === i),
      );
      selectingData.forEach((i) => {
        i.addByLink = true; // 通过关联新增的数据支持删除，以此key表示
        if (i.child?.length > 0) {
          i.child.forEach((el) => (el.addByLink = true));
        }
      });
      this.$emit("submit", selectingData);
      this.$refs.dialog.close();
    },
    closed() {
      this.pageData = {
        pageNum: 1,
        pageSize: 50,
        total: 0,
      };
      this.list = [];
      this.selectedData = [];
      this.choosedList = [];
      window.removeEventListener("taskRefreshable", this.getTableData);
    },
    handlePageSizeChange(pageSize) {
      this.pageData.pageSize = pageSize;
      this.getTableData(true);
    },
    handlePageNumChange(pageNum) {
      this.pageData.pageNum = pageNum;
      this.getTableData();
    },
    handleSelect(arr) {
      this.selectedData = arr;
    },
    toggleRowExpansion(row) {
      const grandSonTable = this.$refs.table.elTable();
      grandSonTable.toggleRowExpansion(row, !row.expand);
      this.$set(row, "expand", !row.expand);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .hb-dialog__wrapper {
  height: 70vh;
}
::v-deep .son-task__char {
  width: 18px;
  height: 18px;
  background: #fff5e5;
  border-radius: 2px;
  color: #ffa000;
  text-align: center;
  line-height: 18px;
  font-size: 12px;
  margin-right: 10px;
}
::v-deep .table-text-overflow {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
::v-deep .el-table__expand-icon {
  display: none !important;
}
::v-deep .el-table__placeholder {
  display: none !important;
}
::v-deep .el-table__indent {
  padding-left: 0 !important;
}
::v-deep .expand-icon {
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 16px;
  background: rgba(255, 160, 0, 0.1);
  margin-left: 20px;
  border-radius: 2px;
  .arrow {
    display: block;
    font-size: 12px;
    color: #ffa000;
    transition: transform 0.3s;
    &.expand {
      transform: rotate(-180deg);
      transition: transform 0.3s;
    }
  }
}
</style>
