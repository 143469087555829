import axios from "axios";
import { debounce } from "lodash";
import store from "../store";
import { Message } from "element-ui";
import { mainMethods } from "@/assets/js/globalUtils";
Message.error = (function (oldError) {
  return function () {
    oldError({
      message: arguments[0],
      offset: 70,
      showClose: true,
    });
  };
})(Message.error);
Message.info = (function (option) {
  return function () {
    option({
      message: arguments[0],
      offset: 70,
      showClose: true,
    });
  };
})(Message.info);
Message.error = debounce(Message.error, 100);
Message.info = debounce(Message.info, 100);

const http = axios.create({
  baseURL: "/platform",
});
http.interceptors.request.use(
  (config) => {
    config.headers.Authorization = store.state.Authorization;
    return config;
  },
  (error) => {
    Message.error("主应用request拦截器报错：" + error.message);
  },
);
http.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.code === "100" || res.code === "200") {
      return Promise.resolve(res.body);
    } else if (res.code === "401") {
      // stage、production环境401跳转至sso登录页,其他环境报401
      if (process.env.VUE_APP_ENV === "dev" || process.env.VUE_APP_ENV === "local") {
      } else {
        Message.info("登录状态已失效, 请重新登录");
        mainMethods.logout(false, true);
      }
    } else if (res.code === "105" || res.code === "106") {
      return Promise.reject(res);
    } else if (response.data.type) {
      // 返回的是文件流，用作下载excel
      return Promise.resolve(response);
    } else {
      Message.error(res.msg || "未知错误");
      return Promise.reject(res);
    }
  },
  (error) => {
    if (error.response.data.status === "401" || error.response.data.code === "401") {
      if (process.env.VUE_APP_ENV === "dev" || process.env.VUE_APP_ENV === "local") {
        Message.info("登录状态已失效, 请重新登录");
      } else {
        Message.info("登录状态已失效, 请重新登录");
        mainMethods.logout(false, true);
      }
    } else {
      Message.error("主应用response拦截器报错：" + error.message);
    }
  },
);

export default http;
